const path = 'redux/Property';
export const PROPERTIES_LOADED = `${path}/PROPERTIES_LOADED`;
export const LOADING_PROPERTIES = `${path}/LOADING_PROPERTIES`;
export const GET_QOUTE = `${path}/GET_QOUTE`;
export const QOUTE_LOADED = `${path}/QOUTE_LOADED`;
export const PROPERTY_LOADED = `${path}/PROPERTY_LOADED`;
export const LOADING_PROPERTY = `${path}/LOADING_PROPERTY `;
export const FAVORITES_LOADED = `${path}/FAVORITES_LOADED`;
export const IDS_LOADED = `${path}/IDS_LOADED`;
export const LOADING_FAVORITES = `${path}/LOADING_FAVORITES`;
export const LOADING_IDS = `${path}/LOADING_IDS`;
export const TOGGLE_SELECTED_PROPERTY = `${path}/TOGGLE_SELECTED_PROPERTY`;
export const CLEAR_PROPERTIES = `${path}/CLEAR_PROPERTIES`;
export const LOADING_RESERVATION_REPORTS = `${path}/LOADING_RESERVATION_REPORTS`;
export const RESERVATION_REPORTS_LOADED = `${path}/RESERVATION_REPORTS_LOADED`;
export const VALID_SEARCH_DESTINATION_CHECK = `${path}/VALID_SEARCH_DESTINATION_CHECK`;
export const FLAG_WISHLIST_MODEL = `${path}/FLAG_WISHLIST_MODEL`;
export const UPDATE_SEARCH_VALUES = `${path}/UPDATE_SEARCH_VALUES`;
